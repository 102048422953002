.title-1 {
  font-size: 3.6rem;
  font-weight: 700;
}

.title-2 {
  font-size: 2.4rem;
  font-weight: 600;

  @include media-breakpoint-down(lg) {
    font-size: 1.8rem;
  }
}

.title-3 {
  font-size: 2rem;
  font-weight: 600;
}

.price {
  color: var(--color-primary-blue);
  font-weight: 700;
  font-family: "Titillium Web";
  font-style: normal;

  // course recommendation
  // modal checkout
  // modal course
  font-size: 2.4rem;

  @include media-breakpoint-down(md) {
    font-size: 1.6rem;
  }

  // category card price
  &.large {
    font-size: 2.6rem;

    @include media-breakpoint-down(lg) {
      font-size: 1.6rem;
    }
  }

  // landing card price
  &.medium {
    font-size: 1.8rem;

    @include media-breakpoint-down(md) {
      font-size: 1.6rem;
    }
  }

  // card list course bundle price
  &.small {
    font-size: 1.6rem;
  }

  // course description
  // modal course
  &.normal {
    font-size: 20px !important;
  }

  strike {
    font-weight: 300;
  }
}
